










import Vue from "vue";
import LoginForm from "@/components/LoginForm.vue"; // @ is an alias to /src

export default Vue.extend({
  name: "Login",
  data() {
    return {
      authUser: null
    };
  },
  components: {
    LoginForm
  }
});
