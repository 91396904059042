


















import Vue from "vue";
import firebase from "firebase/app";
import "firebase/auth";

export default Vue.extend({
  name: "LoginForm",
  data() {
    return {
      email: "",
      password: "",
      authUser: null
    };
  },
  created() {
    firebase.auth().onAuthStateChanged(user => {
      this.$store.state.authUser = user;
    });
  },
  methods: {
    logIn() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => this.navigateToOriginalRoute())
        .catch();
    },
    signOut() {
      firebase.auth().signOut();
    },
    navigateToOriginalRoute() {
      let redirectPath = "/";
      if (this.$route.query.redirect != null) {
        redirectPath = this.$route.query.redirect as string;
      }
      this.$router.push(redirectPath);
    }
  }
});
